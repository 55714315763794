.content-mask > header {
    height: $header-full-height;
    padding: 1.25rem 1.25rem 0 1.25rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 400;
    background-color: $brand-five;

    transition: height 300ms linear;

    @include breakpoint($up-to-mobile) {
        height: 6rem;
    }

    /**
     * .smaller class is added to the header once it's scrolled past
     *  a certain point. We then change the height, and also the color
     * of the elements inside of the header.
     */

    &.smaller {

        height: $header-small-height;
        background-color: $brand-four;

        img {
            width: 3.5rem;
        }

        svg {
            width: 3.5rem;
            bottom: 1.1rem;
            height: 2.25rem;

            .letter-k,
            .number-2 {
                fill: $brand-five;
            }

            .letter-a {
                fill: $brand-five;
            }

        }

        ul.social-links {
            bottom: 0;

            li {
                a::after {
                    color: $brand-five;
                }

                a:hover {
                    &::after {
                        color: $brand-one;
                    }
                }
            }
        }

        .nav-toggle {
            bottom: 0rem;
        }

        .hamburger {
            margin-bottom: 16px;

            &:hover {

                [class*='bar-'] {
                    background-color: $brand-one;
                }

            }
        }

        .hamburger [class*='bar-'] {
            background-color: $brand-five;
        }

    }

    .hamburger {
        transition: margin 300ms linear;
    }

    svg {
        position: absolute;
        left: 50%;
        bottom: 2.35rem;
        width: 9.375rem;
        transform: translateX(-50%);
        transition: width 300ms linear, height 300ms linear;
        height: 6.25rem;

        .letter-k,
        .number-2 {
            fill: $brand-three;
        }

        .letter-a {
            fill: $brand-one;
        }

        @include breakpoint($up-to-mobile) {
            width: 5rem;
            height: 3.25rem;
            bottom: 1.4rem;
        }
    }

    img {
        position: absolute;
        left: 50%;
        bottom: 1rem;
        width: 9.375rem;
        transform: translateX(-50%);
        transition: width 300ms linear;

        @include breakpoint($up-to-mobile) {
            width: 5rem;
        }
    }

    .nav-toggle {
        overflow: hidden;
        position: absolute;
        bottom: 1.25rem;

        .burger-container {
            display: inline-block;
        }

        .text-container {
            display: none;
        }
    }

    .social-links {
        position: absolute;
        bottom: 0.8rem;
        right: 1.25rem;
        z-index: 100;
    }
}
