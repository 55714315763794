.full-width-image-container.about-us {

    overflow: hidden;

    &::after {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 110%;
        height: 10vw;
        left: -0.5rem;
        top: -0.5rem;
        z-index: 100;
        background-image: url(../svg/meet-the-team-slant-bottom.svg);
    }

}

.about-us-grid-container {

    h2 {
        font-weight: bold;
        padding-bottom: 2.5rem;
    }

    @include breakpoint($up-from-ipad) {

    }

    @include breakpoint($up-from-desktop) {
        padding-top: 2.5rem;
    }

    .item-row-container {
        margin-bottom: 3rem;

        h3 {
            font-weight: bold;
            text-transform: uppercase;
            font-size: 1.1rem;
            padding-bottom: 0.8rem;
        }

        @include breakpoint($up-to-ipad) {
            margin-bottom: 0;
        }

        .item-container {
            width: 32%;
            float: left;

            margin-right: 1%;
            margin-left: 1%;

            @include breakpoint($up-to-ipad) {
                margin-left: 0;
                margin-right: 0;
                margin-bottom: 2rem;
            }

            border: 1px solid $brand-one;

            // background-color: #EBEBEB;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            @include breakpoint($up-to-ipad) {
                width: 100%;
                float: none;
            }

            a.a-btn {
                margin-top: 1rem;
            }

            .content {
                padding: 2rem;
            }

        }

    }

}

.full-width-image-container.about-us + .find-out-more-block{
    margin-top: 0;
}

.meet-the-team-block {

    background-color: $brand-one;
    color: $brand-two;
    padding-top: 10%;
    position: relative;
    overflow: hidden;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
        text-transform: none;
        font-weight: bold;
    }

    p {
        color: $brand-five;
    }

    .content-row {
        .content-column {
            overflow: visible;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 110%;
        height: 10vw;
        left: -0.5rem;
        top: 0;
        z-index: 100;
        background-image: url(../svg/meet-the-team-slant-top-single.svg)
    }

    .left-column,
    .right-column {
        width: 50%;
        float: left;

        @include breakpoint($up-to-mobile) {
            width: 100%;
            float: none;
        }
    }

    .left-column {
        padding-top: 0.8rem;
    }

    .right-column {

        position: relative;
        height: 3.6rem;

        a {
            color: $brand-five;
            position: absolute;
            right: 0;
            border: 1px solid $brand-five;

            @include breakpoint($up-to-mobile) {
                position: relative;
                right: auto;
            }

            &:hover {
                color: $brand-one;
            }

            &::after {
                background-color: $brand-five;
            }
        }

    }


}

.about-us-graphic {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include breakpoint(max-width 1000px) {
        padding-bottom: 0;
        padding-top: 0;
    }

    @include breakpoint(max-width 500px) {
        display: none;
    }

}
