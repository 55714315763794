@import "../../node_modules/breakpoint-sass/stylesheets/breakpoint";

html {

    &.fix {
        overflow: hidden !important;

        body {

            overflow: hidden !important;

        }
    }

    height: 100%;

}

body {
    font-family: 'Montserrat', sans-serif;
    color: $brand-two;
    padding-top: 9.375rem + 1.25rem;

    @include breakpoint($up-to-mobile) {
        padding-top: 6rem;
    }

    h1 {
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 1rem;
        @include breakpoint($up-to-mobile) {
            font-size: 1.6rem;
        }
    }

    h2 {
        text-transform: uppercase;
        padding-bottom: 1rem;
        font-size: 1.6rem;
    }

    p {
        line-height: 1.6rem;
        color: $brand-three;
        padding-bottom: 1rem;
    }

    overflow-x: hidden;

    a,
    a:hover,
    a:active,
    a:visited {

        color: $brand-one;

        &:hover {
            color: $brand-three;
            text-decoration: none;
        }

    }

    ul, ol {

        padding-bottom: 1rem;
        padding-left: 1rem;

        li {
            color: $brand-three;
            padding-bottom: 0.8rem;
            font-size: 0.9375rem;
            line-height: 1.6rem;

            &:last-child {
                padding-bottom: 0;
            }
        }

    }

    ul {
        li {
            list-style: disc;
        }
    }



}

body a.a-btn {
    color: $brand-one;
    padding: 1.2rem 5rem 1.2rem 1rem;
    border: 1px solid $brand-one;
    display: inline-block;
    margin-top: 0.2rem;
    position: relative;
    overflow: hidden;
    transition: color 80ms linear;
    z-index: 1;
    margin-bottom: 0.1rem;

    &:hover {
        text-decoration: none;
        color: $brand-five;

        &::after {
            top: 0;
        }
    }

    &.foreground-light {
        color: $brand-five;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        background-color: $brand-one;
        width: 100%;
        height: 100%;
        top: 100%;
        left: 0;
        z-index: -1;
        transition: top 80ms linear;
    }

}

.content-wrapper {
    padding: 0 1.25rem 0 1.25rem;
    position: relative;
}

.content-mask {
    position: relative;
}

.content-column {
    padding-left: 5rem;
    padding-right: 5rem;
    max-width: 1200px;
    overflow: hidden;
    margin: 0 auto;

    @include breakpoint($up-to-mobile) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.content-row {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.full-width-image-container {
    overflow: hidden;
    position: relative;

    height: 640px;

    background-repeat: none;
    background-position: center;
    background-size: cover;

    @include breakpoint($up-to-ipad) {
        height: 450px;
    }

    @include breakpoint(max-width 420px) {
        height: 350px;
    }

}

.two-col {

    .content-row {

        padding-top: 4rem;
        padding-bottom: 4rem;

        .left-column,
        .right-column {
            width: 50%;
            float: left;

            @include breakpoint($up-to-desktop) {
                width: 100%;
                float: none;
                margin-bottom: 0;
            }

        }

        .left-column {
            padding-right: 6rem;

            @include breakpoint($up-to-desktop) {
                padding-right: 0;
                padding-bottom: 1rem;
            }
        }

    }

}

.mini-hero {
    background-color: $brand-one;
    height: 2.5rem;
    width: 100%;
    position: relative;
    margin-bottom: 3.5rem;

    &::after {
        content: '';
        display: block;
        background-color: $brand-five;
        width: 4rem;
        height: 2.5rem;
        position: absolute;
        top: 0;
        right: 4.5rem;
        transform: skewX(45deg);
    }
}

.associated-items-container {

    background-color: $brand-four;
    color: $brand-five;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @include breakpoint($up-to-mobile) {
    }

    h2 {
        text-transform: none;
        font-weight: bold;
        font-size: 1.563rem;
    }

    .list-of-items {

        overflow: auto;
        padding-top: 2rem;
        padding-bottom: 4.8rem;

        @include breakpoint($up-to-mobile) {
            padding-bottom: 2rem;
        }

        li {
            display: inline-block;
            width: 32%;
            margin-right: 2%;
            float: left;
            padding-right: 1rem;

            @include breakpoint($up-to-mobile) {
                float: none;
                width: 100%;
                margin-right: 0;
                margin-bottom: 1.5rem;
            }

            a {
                font-size: 0.9rem;
                text-transform: uppercase;
                font-weight: bold;
                color: $brand-five;
                line-height: 1.2rem;

                &:hover {
                    text-decoration: none;
                    color: $brand-one;
                }
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
        }

    }

    a {
        color: $brand-five;
    }

}

.content-column-two-col {

    @include breakpoint(max-width 950px) {
        overflow: hidden;
    }

    .content-column-left {
        width: 67%;
        float: left;
        margin-right: 3%;
        padding-right: 2rem;

        @include breakpoint(max-width 950px) {
            width: 100%;
            float: none;
        }
    }

    .content-column-right {
        width: 30%;
        float: left;

        @include breakpoint(max-width 950px) {
            width: 100%;
            float: none;
            margin-top: 3rem;
        }
    }

}

.nav-open {

    .background-slant-left,
    .background-slant-right {
        opacity: 0;
    }

}

.background-slant-left,
.background-slant-right {

    display: none;
    position: absolute;
    z-index: -1;
    opacity: 0.25;
    height: 52rem;
    width: 52rem;
    transition: opacity 1s;

    img {
        height: 52rem;
        width: 52rem;
    }

}

.page-template-t-page-home {

    .background-slant-left {
        display: none;
    }

    .background-slant-right {
        display: block;
        position: absolute;
        top: 55rem;
        left: 10%;
    }

}

.page-template-t-page-esg {

    .background-slant-left {
        display: none;
    }

    .background-slant-right {
        display: block;
        position: absolute;
        top: 40rem;
        left: 10%;
    }

}

.single-landingpages {

    .background-slant-left {
        display: none;
    }

    .background-slant-right {
        display: block;
        position: absolute;
        top: 25rem;
        left: 10%;
    }

}

.page-template-t-page-about-us {

    .background-slant-left {
        display: none;
    }

    .background-slant-right {
        display: block;
        top: 55rem;
        left: 40%;
    }

}

.single-news {

    .background-slant-left {
        display: none;
    }

    .background-slant-right {
        display: block;
        top: 10rem;
        left: 10%;
    }

}

.single-casestudies {

    .background-slant-left {
        display: none;
    }

    .background-slant-right {
        display: block;
        top: 17rem;
        left: 30%;
    }

}

.page-template-t-page-resources {

    .background-slant-left {
        display: block;
        top: 50rem;
        left: 50%;

        img {
            transform: translateX(-50%);
        }
    }

    .background-slant-right {
        display: none;
    }

}

.page-template-t-page-team {

    .background-slant-left {
        display: block;
        top: 60rem;
        left: 50%;

        img {
            transform: translateX(-50%);
        }
    }

    .background-slant-right {
        display: none;
    }

}

.page-template-t-page-get-in-touch {

    .background-slant-left {
        display: block;
        top: 48rem;
        left: 8%;
    }

    .background-slant-right {
        display: none;
    }

}


/**
 * Unusual fix for slick slider that was too tall.
 */

.slick-slide {
	height: auto !important;
}
