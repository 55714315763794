.candidate-portal-block-container {

    /**
     * Position.
     */

    position: relative;
    
    /**
     * Display related.
     */

    display: block;
    overflow: hidden;

    /**
     * Z-index.
     */
    z-index: 101;

    /**
     * Color.
     */

    background-color: $brand-one;

    /**
     * .alternate.
     */

    &.alternate {

        /**
         * Color.
         */

        background-color: $brand-four;

    }   

    /**
     * Margin.
     */

    margin-top: 4rem;

    /**
     * .no-margin-top.
     */

    &.no-margin-top {

        /**
         * Margin.
         */

        margin-top: 0;

    }

    /**
     * .candidate-portal-block.
     */

    .candidate-portal-block {
    
        /**
         * p.
         */
    
        p {
    
            /**
             * Font related.
             */
    
            font-size: 1.6rem;
            color: white;
            margin-bottom: 0;
            padding-bottom: 0;
            line-height: 1.9rem;
    
        }
    
    }

    /**
     * :hover.
     */

    &:hover {

        /**
         * Color.
         */

        background-color: #938576;

    }

}
