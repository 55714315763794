/**
 * .content-row.info-strip.
 */

.content-row.info-strip {

    /**
     * Background.
     */

    background-color: $brand-four;

    /**
     * Padding.
     */

    padding-top: 4rem;
    padding-bottom: 3rem;
    
    /**
     * p.
     */

    p {

        /**
         * Font related.
         */

        color: white;

    }

    /**
     * h1, h2, h3, h4.
     */

    h1, h2, h3, h4 {

        /**
         * Font related.
         */

        color: white;

    }

    /**
     * ul.
     */

    ul {

        /**
         * li.
         */

        li {

            /**
             * Font related.
             */

            color: white;

        }
    }

}