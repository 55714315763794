.find-out-more-block {

    background-color: $brand-four;
    color: $brand-five;

    margin-top: 0;
    
    padding-top: 13rem;
    padding-bottom: 3.5rem;

    position: relative;
    overflow: hidden;

    @include breakpoint($up-to-mobile) {
        padding-top: 5rem;
    }

    .color-strip {
        z-index: 0;
        position: absolute;
        top: -15rem;
        right: 0rem;
        height: 32rem;
        width: 17.5rem;
        background-color: $brand-six-alpha;
        transform: skewX(45deg);

        @include breakpoint($up-to-mobile) {
            top: -30rem;
            right: -1rem;
            height: 50rem;
            opacity: 0.5;
        }

        // .inside-color {
        //     position: absolute;
        //     top: 100%;
        //     left: 0;
        //     background-color: $brand-one;
        //     width: 100%;
        //     height: 100%;
        //     transition: top 200ms cubic-bezier(0.775, 0.035, 0.925, 0.395);
        //
        //     @include breakpoint($up-to-mobile) {
        //         display: none;
        //     }
        // }

    }

    // &:hover {
    //     .color-strip {
    //         .inside-color {
    //             top: 0;
    //         }
    //     }
    // }

    h2 {
        margin-top: 0;
        margin-bottom: 1rem;
        text-transform: none;
        font-weight: bold;
    }

    p {
        color: $brand-five;
    }

    .content-row {
        .content-column {
            overflow: visible;
        }
    }

    .left-column,
    .right-column {
        width: 50%;
        float: left;

        @include breakpoint($up-to-mobile) {
            width: 100%;
            float: none;
        }
    }

    .left-column {

    }

    .right-column {

        position: relative;

        a {
            margin-top: 2.8rem;
            position: absolute;
            right: 0;

            @include breakpoint($up-to-mobile) {
                position: relative;
                right: auto;
            }

        }

        // &::before {
        //     content: '';
        //     display: block;
        //     position: absolute;
        //     background-repeat: no-repeat;
        //     background-image: url(../svg/slant-page-background.svg);
        //     height: 80rem;
        //     width: 64rem;
        //     top: -20rem;
        //     right: -25rem;
        //     opacity: 0.1;
        //
        //     @include breakpoint($up-to-mobile) {
        //         top: -30rem;
        //     }
        // }

    }


}
