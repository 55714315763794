/**
 * .homepage-hero.
 */

.homepage-hero {

    /**
     * &.landing-page.
     */

    &.landing-page {

        /**
         * h2.
         */

        h2 {

            /**
             * Font related.
             */

            font-size: 2.6rem;

        }

        /**
         * Margin.
         */

        margin-bottom: 3rem;

        /**
         * Padding.
         */
    
        padding-top: 4rem;
        padding-bottom: 4rem;

        /**
         * &::after.
         */

        &:after {

            /**
             * Position.
             */

            right: 2rem;

            /**
             * Sizing.
             */

            width: 25rem;
            height: 25rem;

        }

    }

}