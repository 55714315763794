.resource-list-container {

    margin-bottom: 5rem;

    h2 {
        font-size: 2rem;
    }

    ul {

        padding-left: 0;

        li {

            list-style: none;

            a {
                display: block;
                color: $brand-two;
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-bottom: 1px solid $brand-six;
                text-decoration: none;
                position: relative;
                transition: padding-left 0.5s;
                padding-right: 2rem;

                &::after {
                    @include fontello;
                    content: '\E801';
                    display: block;
                    height: 2rem;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 2rem;
                    color: $brand-two;
                    transition: right 0.5s;
                }

                &:hover {
                    color: $brand-one;
                    padding-left: 1rem;


                    &::after {
                        color: $brand-one;
                        //right: 1rem;
                    }
                }

                span {

                    &:nth-child(1) {
                        font-size: 0.9375rem;
                        text-transform: uppercase;
                        padding-right: 0.25rem;
                        font-weight: bold;
                        line-height: 1.3rem;

                        @include breakpoint(max-width 960px) {
                            display: block;
                            padding-bottom: 0.5rem;
                        }
                    }

                    &:nth-child(2) {
                        color: $brand-four;

                        @include breakpoint(max-width 960px) {
                            display: block;
                        }
                    }

                }

            }

        }

    }

}

.case-studies-list {

    .item-container {
        border: 1px solid $brand-one;
        // min-height: 17.5rem !important;

        min-height: 21.5rem !important;

        @include breakpoint(max-width 1200px) {
            min-height: 24.5rem !important;
        }

        @include breakpoint(max-width 980px) {
            min-height: 27.5rem !important;
        }

        @include breakpoint(max-width 840px) {
            min-height: 29rem !important;
        }

        @include breakpoint(max-width 768px) {
            min-height: 24.5rem !important;
        }

        @include breakpoint(max-width 600px) {
            min-height: 20.5rem !important;
        }

        a {
            color: $brand-two;
            
            padding-top: 1rem;

            @include breakpoint(max-width 600px) {
                position: relative !important;
            }

            img.icon {
                width: 6rem;
                margin-bottom: 0.8rem;
                margin-top: 0.8rem;
            }

            .item-background-color {
                @include breakpoint(max-width 600px) {
                    display: none;
                }
            }

            .inner-content-container {
                position: relative;
                bottom: auto;
                padding-left: 0;
                padding-right: 0;
                // bottom: 2rem !important;

                .heading {
                    font-size: 1.375rem;
                    line-height: 1.7rem;
                    padding-bottom: 0.8rem;
                    color: $brand-two;
                    text-transform: none;
                }

                p {
                    color: $brand-two;
                    opacity: 1 !important;
                    position: relative;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    color: $brand-three;
                }

            }


        }
    }

}
