// Remember: do NOT edit this file.

$brand-six: #EBEBEB;

$brand-five: #FFFFFF;

$brand-four: #414A69;

$brand-three: #54555B;

$brand-two: #000000;

$brand-one: #B5BD0E;
