.testimonials-area {
    background-color: $brand-one;
    text-align: center;
    color: $brand-five;
    position: relative;
    border-bottom: 5px solid $brand-one; /* Fix to prevent gap in IE */
    overflow: hidden;

    .content-row {
        padding: 0;
    }


    > .content-row {

        margin-top: -0.2rem;
        padding-bottom: 1rem;
        padding-top: 23%; /* matches height of &::before */

        &::before {

            content: '';
            display: block;
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            width: 110%;
            height: 23vw;
            left: -0.5rem;
            top: -1.0rem;
            z-index: 100;
            background-image: url(../svg/testimonial-slant-top-single.svg);
        }

    }

    img {
        width: 90px;
        margin-bottom: 1rem;
    }

    svg {
        width: 90px;
        height: 70px;
        margin-bottom: 1.5rem;

        .letter-k,
        .number-2 {
            fill: $brand-five;
        }

        .letter-a {
            fill: $brand-five;
        }

    }

    h2 {
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 1.5rem;

        @include breakpoint($up-to-mobile) {
            margin-bottom: 0.2rem;
            margin-top: 1rem;
        }
    }

    p {
        padding-bottom: 0;
        line-height: 1.8rem;
        color: $brand-five;

        @include breakpoint(max-width 900px) {
            font-size: 0.95rem;
        }
    }

    p.author {
        padding-top: 1rem;
        font-size: 0.95rem;
        font-weight: bold;
        line-height: 1.3rem;
    }

    .slide-container {

        * {
            outline-style: none;
        }

        .content-column {
            padding-left: 5rem;
            padding-right: 5rem;

            @include breakpoint(max-width 900px) {
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }

        .slick-slide {
            width: 500px;

            p {
                opacity: 0.5;
                transition: opacity 200ms linear;

                @include breakpoint($up-to-mobile) {
                    opacity: 1;
                }
            }

        }

        .slick-center {
            p {
                opacity: 1;
            }
        }
    }

    .dots-container {
        ul {
            margin-left: 0.5rem;
            margin-top: 2rem;

            li {
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 0.5rem;
                margin-right: 0.5rem;
                border-radius: 1rem;
                display: inline-block;
                padding: 0 !important;
                list-style: none;

                cursor: pointer;

                button {
                    display: none;
                }

                background-color: $brand-five;

                &.slick-active {
                    background-color: $brand-five;
                }
            }
        }
    }

}

.full-width-image-container.with-testimonial-bottom {

    &::before {

        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 110%;
        height: 20vw;
        left: -0.5rem;
        top: -20px;
        background-color: transparent;
        z-index: 100;
        background-image: url(../svg/testimonial-slant-bottom.svg)

    }

}
