/**
 * .text-panel.
 */

.text-panel {

    /**
     * Margin.
     */

    margin-bottom: 2rem;

    /**
     * h2, h3, h4.
     */

    h2, h3, h4 {

        /**
         * Font related.
         */

        font-weight: bold;

        /**
         * Margin.
         */

        margin-top: 1rem;

    }

}

/**
 * .button-panel.
 */

.button-panel {

    /**
     * Margin.
     */

    margin-bottom: 2rem;

}

/**
 * .image-panel.
 */

.image-panel {

}