.hamburger {
  position: relative;
  padding: 8px 2px 10px 0;
  background: transparent;
  border: 1px solid transparent;
  margin: 7px 0 7px 0;
}

.hamburger:focus {
  outline-width: 0;
}

.hamburger [class*='bar-'] {
  background: black;
  background: $brand-two;
  display: block;
  transform: rotate(0deg);
  transition: .2s ease transform, opacity;
  opacity: 1;

  /*
   * ENLARGED FOR PRESENTATION
   * Keep these values at the same proportion
   * for it to look correct
   *
   * border-radius: 8px;
   * height: 8px;
   * width: 100px;
   * margin-bottom: 16px;
  */

  border-radius: 2px;
  height: 2px;
  width: 25px;
  margin-bottom: 4px;

  text-indent: -9999px;
}

.hamburger .bar-bot {
  margin-bottom: 0;
}

.opened .bar-top {
  transform: rotate(45deg);
  transform-origin: 15% 15%;
}
.opened .bar-mid {
  opacity: 0;
}
.opened .bar-bot {
  transform: rotate(-45deg);
  transform-origin: 15% 95%;
}
