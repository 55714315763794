body {

    .content-wrapper {
        transition: all 400ms cubic-bezier(0.775, 0.035, 0.925, 0.395);
    }

    .content-fade {
        transition: opacity 400ms cubic-bezier(0.775, 0.035, 0.925, 0.395);
    }

    &.nav-open {

        .navigation-container {
            display: block;

            transform: translateX(0);
        }

        .content-wrapper {

            transform: translateX(45vw);

            @include breakpoint($up-to-desktop) {
                transform: translateX(60vw);
            }

            @include breakpoint($up-to-mobile) {
                transform: translateX(65vw);
            }

            @include breakpoint(max-width 470px) {
                transform: translateX(100vw);
            }

            @include breakpoint(min-width 1600px) {
                transform: translateX(25vw);
            }

        }

        .content-fade {
            opacity: 0.2;
            pointer-events: none;
        }

    }
}


.navigation-container {

    background-color: $brand-six;
    border-left: 1.25rem solid $brand-five;
    position: fixed;
    left: 0;
    top: 0;
    width: 45vw;
    height: 100vh;

    @include breakpoint($up-to-mobile) {
        padding-top: 6rem;
    }

    padding-top: 9.375rem + 1.25rem;

    transform: translateX(-45vw);
    transition: transform 400ms cubic-bezier(0.775, 0.035, 0.925, 0.395);

    @include breakpoint($up-to-desktop) {
        width: 60vw;
        transform: translateX(-60vw);
    }

    @include breakpoint($up-to-mobile) {
        width: 65vw;
        transform: translateX(-65vw);
    }

    @include breakpoint(max-width 470px) {
        width: 100vw;
        transform: translateX(-100vw);
        border-right: 1.5rem solid $brand-five;
    }

    @include breakpoint(min-width 1600px) {
        width: 25vw;
        transform: translateX(-25vw);
    }

    nav {

        ul.nav,
        ul.menu {

            li {
                list-style: none;
                margin-bottom: 0.2rem;
                overflow: hidden !important;
                padding-bottom: 0;

                @include breakpoint($up-to-mobile) {
                    margin-bottom: 0.1rem;
                }

                &.current-menu-item {
                    a {
                        color: $brand-five;

                        &::before {
                            top: 0;
                        }
                    }
                }

                a {
                    font-size: 2rem;
                    text-transform: uppercase;
                    font-weight: bold;
                    color: $brand-two;
                    text-decoration: none;
                    display: inline-block;
                    padding-right: 7rem;
                    padding-left: 1rem;
                    padding-top: 0.7rem;
                    padding-bottom: 0.7rem;
                    overflow: hidden !important;
                    transition: color 100ms linear;
                    line-height: 1.6rem;

                    @include breakpoint($up-to-mobile) {
                        font-size: 1.6rem;
                        padding-right: 3rem;
                        padding-top: 0.35rem;
                        padding-bottom: 0.35rem;
                    }

                    &:hover {
                        color: $brand-five;
                        background-color: transparent;

                        &::before {
                            top: 0;
                        }
                    }

                    &::before {
                        content: '';
                        display: block;
                        position: absolute;
                        background-color: $brand-one;
                        width: 100%;
                        height: 100%;
                        top: 100%;
                        left: 0;
                        z-index: -1;
                        transform: translateX(-0.5rem);
                        transition: top 100ms linear;
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        background-repeat: no-repeat;
                        background-size: cover;
                        width: 5rem;
                        height: 10rem;
                        top: -1rem;
                        right: -0.1rem;
                        background-image: url(../svg/menu-slant.svg);

                        @include breakpoint($up-to-mobile) {
                            right: -1.8rem;
                        }
                    }

                }

            }

        }

        ul.contact {

            padding-left: 0;
            margin-top: 3rem;

            li {
                padding-left: 1rem;
                font-size: 0.9rem;
                font-weight: bold;
                margin-bottom: 0.25rem;
                padding-bottom: 0rem;
                text-transform: uppercase;
                list-style: none;
                color: $brand-two;
                line-height: 1rem;

                a {
                    color: $brand-two;
                    text-decoration: none;
                    font-size: 0.9rem;

                    &:hover {
                        color: $brand-one;
                    }
                }
            }
        }

    }

}
