/* extra extra small devices */
$xxxs: max-width 320px;

/* extra small devices */
$xxs: max-width 480px;

/* small tablet and mobile */
$xs: max-width 767px;

/* small desktop */
$sm: 768px 991px;

/* standard desktop */
$md: min-width 992px;

/* large desktop */
$lg: min-width 1200px;

/* extra large devices */
$xl: min-width 1890px;

/* vertical layout adapt tall */
$vert-adapt-tall: max-height 1000px;

/* vertical layout adapt short */
$vert-adapt-short: max-height 750px;

/* extra extra small devices -> to -> standard desktop */
$xxxs-md: max-width 991px;

/* extra extra small devices -> to -> large desktop */
$xxxs-lg: max-width 1199px;

/* extra small devices -> to -> standard desktop */
$xxs-md: 321px 1999px;



$xxs-up: min-width 481px;
$xs-up: min-width 768px;
$iphone-5-sized-devices: max-width 320px;
$up-to-small-tablet-and-mobile: max-width 768px;
$up-to-standard-desktop: max-width 991px;





$up-to-ipad: max-width 768px;
$up-from-ipad: min-width 769px;

$up-to-desktop: max-width 999px;
$up-to-mobile: max-width 767px;

$up-from-desktop: min-width 1000px;
