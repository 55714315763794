.what-we-do-container {

    padding-top: 2rem;
    padding-bottom: 3rem;
    background-color: $brand-four;
    color: $brand-five;
    position: relative;
    overflow: hidden;

    padding-top: 10%; /* matches height of &::before */

    &.homepage {
        padding-bottom: 0;

        @include breakpoint($up-to-mobile) {
            padding-top: 3rem;
        }

        .content-row {
            .content-column {
                div {
                    @include breakpoint($up-to-mobile) {
                        padding-bottom: 2rem;
                    }
                }
            }
        }

    }


    &::before {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 110%;
        height: 10vw;
        left: -0.5rem;
        top: 0;
        z-index: 100;
        background-image: url(../svg/what-we-do-block-slant-top.svg)
    }

    h2 {
        padding-bottom: 3rem;
        font-weight: bold;
    }

    p {
        color: $brand-five;
    }

    .content-row {

        .content-column {

            div {

                width: 25%;
                float: left;
                padding-right: 1rem;
                padding-bottom: 1rem;

                @include breakpoint($up-to-desktop) {
                    width: 50%;
                }

                @include breakpoint($up-to-mobile) {
                    width: 100%;
                    float: none;
                }

                p.heading {
                    text-transform: uppercase;
                    font-size: 1.2rem;
                    font-weight: bold;
                }

            }

        }

    }

}
