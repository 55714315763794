.intro-row {

    h1, h2 {
        font-weight: bold;
    }

    p.subheading {
        font-weight: bold;
        color: $brand-two;
        font-size: 0.9rem;
        padding-top: 0.7rem;
    }

    &.intro-row-one {
        margin-top: 5rem;
        position: relative;
        overflow: visible;

        p + h2 {
            padding-top: 1.5rem;
        }

        @include breakpoint($up-to-desktop) {
            margin-bottom: 0;
            margin-top: 3rem;
        }

        &.get-in-touch {
            margin-bottom: 10rem;

            @include breakpoint($up-to-desktop) {
                margin-bottom: 5rem;
            }
        }

        &.homepage {

            @include breakpoint(min-width 800px) {

            }

            .right-column {
                @include breakpoint($up-to-mobile) {
                    margin-bottom: 0rem;
                }
            }

            @include breakpoint($up-to-mobile) {
                margin-bottom: 0rem;
            }

        }

        .content-row {
            overflow: visible;
            position: relative;
        }

        .content-column {
            overflow: visible;
        }

        .left-column {

            @include breakpoint($up-to-desktop) {
                padding-bottom: 1rem;
            }

        }

        &.about-us {
            padding-bottom: 0;
            overflow: visible;
            margin-bottom: 0;
        }

    }


    .airplane-svg-container {
        transform: translate3d(0,0,0);
        position: absolute;
        top: 0;

        svg {
            transform: translate3d(0,0,0);
        }

        @include breakpoint(max-width 1000px) {
            position: relative;
            top: auto;

            svg {
                width: 100vw;
                height: 83vw;
            }
        }

    }

    .svg-home-primary {
        width: 544px;
        height: 452px;
        right: -1.5rem;

        @include breakpoint(max-width 1000px) {
            width: 100%;
            height: auto;

            &::after {
                content: "";
                clear: both;
                display: table;
            }
        }

        @include breakpoint(min-width 1200px) {
            svg {
                max-width: 544px;
                max-height: 452px;
            }
        }

        @include breakpoint(min-width 600px) {
            svg {
                width: 50vw;
                height: 41.5vw;
                float: right;
            }
        }

        @include breakpoint(max-width 999px) {
            padding-bottom: 1rem;
        }
    }

    .svg-home-extra {
        width: 581px;
        height: 403px;

        left: -2.5rem;
        top: 10rem;

        @include breakpoint(max-width 999px) {
            top: 0;
            display: none;
        }

        @include breakpoint(min-width 1200px) {
            svg {
                max-width: 581px;
                max-height: 403px;
            }
        }
    }

    .svg-about-us-primary {
        width: 581px;
        height: 403px;
        left: -3rem;
        top: 13rem;

        @include breakpoint(max-width 999px) {
            top: 0;
            width: 100%;
            height: auto;

        }

        @include breakpoint(min-width 600px) {
            svg {
                width: 50vw;
                height: 41.5vw;
            }
        }

        @include breakpoint(min-width 1200px) {
            svg {
                max-width: 581px;
                max-height: 403px;
            }
        }
    }

    .svg-team-primary {
        width: 581px;
        height: 403px;
        left: -3rem;
        top: 10rem;

        @include breakpoint(max-width 1000px) {
            top: 0;
            width: 100%;
            height: auto;
            margin-bottom: 1.5rem;
            margin-top: 1rem;

            svg {
                width: 100vw;
                height: 40vw;
            }
        }

        @include breakpoint(min-width 600px) {
            svg {
                width: 50vw;
                height: 20vw;
            }
        }

        @include breakpoint(min-width 1200px) {
            svg {
                max-width: 581px;
                max-height: 403px;
            }
        }
    }

    .svg-get-in-touch-primary {
        width: 430px;
        height: 452px;
        left: -1.5rem;
        top: 10rem;

        @include breakpoint(max-width 1000px) {
            top: 0;
            display: none;
        }

        @include breakpoint(min-width 600px) {
            svg {

            }
        }

        @include breakpoint(min-width 1200px) {
            svg {
                max-width: 430px;
                max-height: 452px;
            }
        }
    }

    &.intro-row-two {
        margin-bottom: 9rem;
        position: relative;

        .right-column {
            position: relative;
        }



        .content-row {
            overflow: visible;
        }

        @include breakpoint($up-to-desktop) {
            margin-bottom: 4rem;
        }

        @include breakpoint($up-to-mobile) {
            margin-bottom: 6rem;
        }

        &.homepage {
            .left-column {

                @include breakpoint($up-to-desktop) {
                }

                @include breakpoint($up-to-mobile) {
                    padding-top: 0;
                }
            }
        }

    }

    &.intro-row-three {
        margin-bottom: 6rem;

        @include breakpoint($up-to-desktop) {
            margin-bottom: 5rem;
        }

        @include breakpoint($up-to-mobile) {
            margin-bottom: 3rem;
        }

        .left-column {
            @include breakpoint($up-to-desktop) {
                padding-bottom: 5rem;
            }

            @include breakpoint($up-to-mobile) {
                padding-bottom: 6rem;
            }
        }

        .right-column {
            padding-bottom: 0.1rem;

            @include breakpoint($up-to-mobile) {
                padding-bottom: 4rem;
            }

        }
    }


    .content-row {
        overflow: hidden;

        @include breakpoint($up-to-desktop) {
            padding-bottom: 0;
            padding-top: 0;
        }
    }

    h1 {
        margin-top: 0;
    }

    .left-column,
    .right-column {
        width: 50%;
        float: left;

        @include breakpoint($up-to-desktop) {
            width: 100%;
            float: none;
            margin-bottom: 0;
        }

    }

    .left-column {
        padding-right: 6rem;

        @include breakpoint($up-to-desktop) {
            padding-right: 0;
        }
    }

    .right-column {

    }

}
