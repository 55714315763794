.items-container {

    .content-row {
        padding-bottom: 0;
        padding-top: 0;
    }

    /**
     * Make the items into a three col grid
     */

    &.three-col {

        .item-container {

            @include breakpoint($up-from-ipad) {

                margin-right: 3%;
                margin-bottom: 3%;
                width: 31.33%;

                &:nth-child(3n) {

                    margin-right: 0;

                    &::after {
                        content: '';
                        display: block;
                        clear: both;
                    }

                }

            }

            @include breakpoint($up-to-ipad) {

                margin-right: 4%;
                margin-bottom: 4%;
                width: 48%;

                &:nth-child(even) {
                    margin-right: 0;
                }

            }

            @include breakpoint(max-width 600px) {

                margin-right: 0;
                margin-bottom: 2rem;
                width: 100%;

                &:nth-child(even) {
                    margin-right: 0;
                }

            }

        }

    }

    .item-container {
        position: relative;
        float: left;
        min-height: 21rem;
        padding: 2rem;
        overflow: hidden;

        a {
            z-index: 10;
            position: absolute;
            width: 100%;
            height: 100%;
            bottom: 0;
            left: 0;
            padding: 2rem;
            text-decoration: none;
            color: $brand-five;

            .inner-content-container {

                position: absolute;
                bottom: 2rem;
                left: 0;
                width: 100%;
                padding-left: 2rem;
                padding-right: 2rem;
                transition: transform .5s;

                p {
                    position: absolute;
                    left: 0;
                    padding-left: 2rem;
                    padding-right: 2rem;
                    padding-bottom: 0;
                    padding-top: 0.2rem;
                    color: $brand-five;
                    opacity: 0;
                    transition: opacity .5s;
                }

            }

            .heading {
                font-size: 1rem;
                text-transform: uppercase;
                line-height: 1.3rem;
                font-weight: bold;
            }

            &:hover {

                .item-background-color {
                    transform: translateY(-10%) skewY(6deg);
                }

                .heading {
                    color: $brand-five;
                }

            }

        }

        &:hover {
            .photo-container {
                top: -2rem;
            }
        }

        .photo-container {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            transition: top .4s;
        }

        .item-background-color {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 101%;
            height: 130%;
            background-color: $brand-one;
            transition: transform .4s;
            transform: translateY(55%) skewY(6deg);

            @include breakpoint(max-width 600px) {
                transform: translateY(62%) skewY(6deg);
            }
        }

        &.background-color-starts-outside-container {
            .item-background-color {
                transform: translateY(100%) skewY(6deg);
            }
        }

    }

}
