.case-study {

    h1 {

    }

    .case-study-content-footer {
        padding-top: 1rem;

        a {
            margin-right: 0.8rem;
        }
    }

}

.case-study .sidebox {
    background-color: $brand-one;
    padding: 2rem;
    color: $brand-two;
    margin-bottom: 1rem;

    @include breakpoint(max-width 950px) {

    }

    p {
        color: $brand-five;
    }

    h3 {
        text-transform: uppercase;
        padding-bottom: 1rem;
        font-weight: bold;
    }

    ul {
        padding-left: 1.2rem;

        li {
            color: $brand-two;
            list-style: disc;
            padding-bottom: 0.8rem;
            line-height: 1.3rem;

            &:last-child {
                padding-bottom: 0;
            }
        }

    }
}

.case-study .quote-container {
    margin-top: 2rem;
    margin-bottom: 4rem;
    position: relative;

    @include breakpoint(max-width 950px) {
        margin-top: 0.5rem;
    }

    &::before {
        @include fontello;
        content: '\E803';
        display: block;
        height: 3rem;
        width: 3rem;
        position: absolute;
        left: 0;
        top: 2.9rem;
        font-size: 2.5rem;
        color: $brand-one;
    }

    h3 {
        color: $brand-two;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 1rem;
        font-size: 1.4rem;
    }

    p {
        font-size: 0.9375rem;
        padding-left: 4.5rem;
        position: relative;
        line-height: 1.6rem;
        padding-bottom: 0.8rem;
    }

    .quote-author {
        padding-left: 4.5rem;
        color: $brand-one;
    }
}
