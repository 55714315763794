.page-template-t-page-download {

    .content-row {

        padding-top: 0;

    }

    .intro-row {

        margin-bottom: 2rem !important;

    }

    .right-column {

        padding-top: 4rem;

    }

    form {

        padding-top: 1rem;

    }

}

.download-slant-block {

    color: $brand-two;
    padding-top: 24%;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-size: cover;
        width: 110%;
        height: 21vw;
        left: -0.5rem;
        top: 0;
        z-index: 100;
        background-image: url(../svg/download-slant-bottom.svg);
    }

}
