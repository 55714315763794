/**
 * footer.
 */

footer {

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * .content-row.
     */

    .content-row {

        /**
         * Padding.
         */

        padding-top: 4rem;

        /**
         * Margin.
         */

        margin-bottom: 2rem;

        /**
         * .content-column.
         */

        .content-column {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: row;

            /**
             * @include.
             */

            @include breakpoint($up-to-mobile) {
                
                /**
                 * Flex.
                 */

                flex-direction: column;

            }

        }

        /**
         * .left-column.
         */

        .left-column {

            /**
             * Display.
             */

            display: flex !important;
            
            /**
             * Flex.
             */ 

            flex-direction: column;
            flex-grow: 1;

            /**
             * @include.
             */

            @include breakpoint(min-width 768px) {

                /**
                 * Sizing.
                 */

                max-width: 50%;

            }
            
        }

        /**
         * .right-column.
         */

        .right-column {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            justify-content: flex-end;
            flex-grow: 1;

            /**
             * @include.
             */

            @include breakpoint(min-width 768px) {

                /**
                 * Sizing.
                 */

                max-width: 50%;

            }

            /**
             * .logo-set.
             */

            .logo-set {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * .logo-container.
                 */

                .logo-container {
                    
                    /**
                     * Flex.
                     */

                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;

                    /**
                     * &:nth-child(1).
                     */

                    &:nth-child(1) {
                        
                    }

                    /**
                     * &:nth-child(1).
                     */

                    &:nth-child(2) {
                        
                    }

                    /**
                     * &:nth-child(3).
                     */

                    &:nth-child(3) {
                        
                    }                    
                    
                }

                img {
                    max-height: 3.5rem;
                    max-width: 90%;
                }

            }

            /**
             * .service-now-container.
             */

            .service-now-container {
                
                /**
                 * Padding.
                 */

                padding-top: 1rem;

                /**
                 * Font related.
                 */

                text-align: center;

                /**
                 * img.
                 */

                img {

                    /**
                     * Sizing.
                     */

                    max-width: 300px;

                }

            }

        }

        /**
         * .content-column.
         */

        .content-column {

            /**
             * Sizing.
             */

            width: 100%;

            /**
             * div.
             */

            div {

                /**
                 * ul.
                 */

                ul {

                    /**
                     * Padding.
                     */

                    padding: 0;

                    /**
                     * Margin.
                     */

                    margin: 0;

                    /**
                     * li.
                     */

                    li {

                        /**
                         * List.
                         */

                        list-style: none;
                        padding: 0;
                        margin: 0;
                        font-size: 0.75rem;
                        margin-bottom: 0.4rem;
                        color: $brand-two;
                    }
                }
            }

            ul {
                li {
                    line-height: 0.9rem;
                }
            }

            /**
             * .address-column.
             */

            .address-column {
                
                /**
                 * Padding.
                 */

                padding-right: 12rem;

                /**
                 * @include.
                 */

                @include breakpoint($up-to-desktop) {

                    /**
                     * Padding.
                     */

                    padding-right: 0;

                }

                /**
                 * @include.
                 */

                @include breakpoint($up-to-mobile) {

                    /**
                     * Padding.
                     */

                    padding-right: 0;

                }

                /**
                 * @include.
                 */

                @include breakpoint(max-width 767px) {

                    /**
                     * Font related.
                     */

                    text-align: center;

                    /**
                     * Margin.
                     */

                    margin-bottom: 2rem;

                }

                /**
                 * ul.
                 */

                ul {

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Display.
                         */

                        display: inline-block;

                        /**
                         * &::after.
                         */

                        &::after {
                            content: ',';
                        }
                        
                        /**
                         * &:last-child.
                         */

                        &:last-child {

                            /**
                             * &::after.
                             */

                            &::after {
                                
                                /**
                                 * content.
                                 */

                                content: none;

                            }

                        }

                    }

                }

            }

            /**
             * .contact-column.
             */

            .contact-column {

                /**
                 * Padding.
                 */

                padding-bottom: 0.5rem;

                @include breakpoint($up-to-mobile) {
                    padding-left: 0;
                }

                @include breakpoint(max-width 767px) {
                    text-align: center;
                }

                ul {

                    li {
                        font-weight: bold;

                        ul {
                            li {
                                display: inline-block;
                            }
                            
                        }
                    }
                }

                a {
                    color: $brand-two;
                    font-size: 0.75rem;

                    &:hover {
                        color: $brand-one;
                        text-decoration: none;
                    }
                }
            }

            .brand-column {
                
                position: relative;

                padding-bottom: 1rem;

                display: flex;

                @include breakpoint(max-width 767px) {
                    text-align: center;
                }

                img {
                    width: 80px;
                    display: inline-block;
                    float: right;
                    margin-right: 1rem;

                    @include breakpoint($up-to-mobile) {
                        margin-right: 0;
                    }
                }

                svg {
                    width: 80px;
                    height: 70px;
                    display: inline-block;
                    // float: right;
                    margin-right: 1rem;

                    .letter-k,
                    .number-2 {
                        fill: $brand-three;
                    }

                    .letter-a {
                        fill: $brand-one;
                    }

                    @include breakpoint(max-width 1000px) {
                        margin-right: 0;
                    }
                }

                p {
                    font-size: 0.75rem;
                    // float: right;
                    padding-top: 3.1rem;
                    padding-bottom: 0;
                    margin-bottom: 0;
                    font-weight: bold;
                    color: $brand-one;
                    text-transform: uppercase;
                    line-height: 1rem;

                    @include breakpoint(max-width 1000px) {
                        padding-top: 0.5rem;
                    }
                }
            }

            @include breakpoint($up-to-desktop) {

                .address-column,
                .contact-column,
                .brand-column {
                    width: 100%;
                    float: none;
                    display: block;
                    // text-align: center;
                }

                .address-column {
                    // margin-bottom: 2rem;
                }

                .brand-column {
                    img {
                        float: none;
                    }
                    svg {
                        float: none;
                        margin-top: 0.5rem;
                    }
                    p {
                        float: none;
                    }
                }


            }


        }

    }

    ul.social-links {
        li {
            a::after {
                color: $brand-two;
            }

            a:hover {
                &::after {
                    color: $brand-one;
                }
            }
        }
    }

}
