.news-items.associated-items-container {

    .list-of-items {
        li {
            a {
                text-transform: none !important;
            }
        }
    }

}

.items-container.news {

    .heading {

        text-transform: none !important;

    }

}

.news-template-default {

    h1 {

        text-transform: none !important;

    }

}

.news-item {

    margin-bottom: 3rem;

    .content-column-right {
        float: right !important;
        position: relative;

        @include breakpoint(max-width 950px) {
            margin-top: 0;
        }

        img {
            width: 100%;
            margin-bottom: 1rem;

            @include breakpoint(max-width 950px) {
                margin-bottom: 3.5rem;
            }
        }

        .date {
            margin-top: 0;
            color: $brand-three;
            font-weight: normal;
            font-size: 0.8125rem;

            @include breakpoint(max-width 950px) {
                padding-top: 0.5rem;
            }
        }

        .social-links {
            margin-bottom: 1rem;

            @include breakpoint(max-width 950px) {
                position: absolute;
                top: 0;
                right: -1rem;
            }
        }
    }

    .content-column-left {
        float: left !important;
    }


}
