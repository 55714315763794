/**
 * .page-template-t-page-esg .homepage-hero.
 */

.page-template-t-page-esg .homepage-hero {

    /**
     * .content-column.
     */

    .content-column {

        /**
         * Padding.
         */

        padding-right: 0;

    }

    /**
     * h2.
     */

    h2 {

        /**
         * Font related.
         */

        font-size: 55px;
        line-height: 65px;

        /**
         * @include.
         */

        @include breakpoint(max-width 800px) {

            /**
             * Font related.
             */

            font-size: 45px;

        }

        /**
         * sub.
         */

        sub {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Font related.
             */

            font-size: 30px;

            /**
             * Margin.
             */

            margin-left: -5px;

        }

    }

}

/**
 * .esg-intro.
 */

.esg-intro {

    /**
     * Padding.
     */

    padding-top: 70px;

    /**
     * Margin.
     */

    margin-bottom: 0 !important;

    /**
     * .mainheading.
     */

    .mainheading {

        /**
         * Margin.
         */

        margin-bottom: 40px;

        /**
         * Font related.
         */

        font-size: 2rem;
        line-height: 2.7rem;

    }

    /**
     * h2.
     */

    h2 {

        /**
         * Font related.
         */

        font-size: 1.3rem;
        line-height: 1.8rem;

    }

    /**
     * .content-row.
     */

    .content-row {

        /**
         * .airplane-svg-container.
         */

        .airplane-svg-container {

            /**
             * @include.
             */

            @include breakpoint(min-width 1001px) {

                /**
                 * Margin.
                 */

                margin-top: 100px;

            }

            /**
             * @include.
             */

            @include breakpoint(max-width 1000px) {

                /**
                 * Margin.
                 */

                margin-bottom: 50px;

            }

        }

        /**
         * @include.
         */

        @include breakpoint(max-width 1000px) {

            /**
             * Display.
             */

            display: flex;
            
            /**
             * Flex.
             */

            flex-direction: column-reverse;
            
            /**
             * .left-column.
             */

            .left-column {

                /**
                 * Sizing.
                 */

                width: 100%;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;

            }

        }        

    }

}

/**
 * .esg-two-col-row.
 */

.esg-two-col-row {

    /**
     * strong.
     */

    strong {

        /**
         * Font related.
         */

        color: $brand-one;

    }

    /**
     * .heading-column.
     */

    .heading-column {

        /**
         * 
         */

        .left-column {

            /**
             * Padding.
             */

            // padding-right: 20px;

        }

    }

    /**
     * h2.
     */

    h2 {

        /**
         * Font related.
         */

        font-size: 1.3rem;
        line-height: 1.8rem;

    }

    /**
     * @include.
     */

    @include breakpoint(max-width 1000px) {

        /**
         * .left-column.
         */

        .left-column {

            /**
             * Pading.
             */

            padding-bottom: 0 !important;

        }

    }

}


/**
 * .esg-benefits.
 */

.esg-benefits {

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Margin.
     */

    margin-bottom: 0 !important;

    /**
     * Padding.
     */

    padding-top: 120px;

    /**
     * Clip.
     */

    clip-path: polygon(0 0, 100% 28%, 100% 100%, 0% 100%);

    /**
     * @include.
     */

    @include breakpoint(max-width 1000px) {

        /**
         * Clip.
         */

        clip-path: polygon(0 0, 100% 20%, 100% 100%, 0% 100%);        

        /**
         * Padding.
         */

        padding-bottom: 40px;

    }

    /**
     * .content-row.
     */

    .content-row {
        
        /**
         * Padding.
         */

        padding-bottom: 3rem;

    }

    /**
     * ul.
     */

    ul {

        /**
         * li.
         */

        li {

            /**
             * Font related.
             */

            color: $brand-two;

        }

    }

    /**
     * @include.
     */

    @include breakpoint(max-width 1000px) {

        /**
         * .left-column.
         */

        .left-column {

            /**
             * Pading.
             */

            padding-bottom: 0 !important;

        }

    }

}

/**
 * .esg-versions.
 */

.esg-versions {

    /**
     * .content-row.
     */

    .content-row {

        /**
         * Padding.
         */

        padding-top: 0;
        padding-bottom: 0;

    }

    /**
     * @include.
     */

    @include breakpoint(max-width 1000px) {

        /**
         * .left-column.
         */

        .left-column {

            /**
             * Pading.
             */

            padding-bottom: 0 !important;

        }

    }

    /**
     * .options-container.
     */

    .options-container {

        /**
         * Margin.
         */

        margin-top: 30px;

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: space-between;

        /**
         * @include.
         */

        @include breakpoint(max-width 1000px) {

            /**
             * Flex.
             */

            flex-direction: column;

        }

        /**
         * .option-container.
         */

        .option-container {

            /**
             * Flex.
             */

            flex-basis: 31.5%;

            /**
             * Sizing.
             */

            width: 31.5%;

            /**
             * @include.
             */

            @include breakpoint(max-width 1000px) {

                /**
                 * Flex.
                 */

                flex-basis: 100%;

                /**
                 * Sizing.
                 */

                width: 100%;

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

            }

            /**
             * h2.
             */

            h2 {

                /**
                 * Font related.
                 */

                font-size: 1.3rem;
                color: white;

                /**
                 * Padding.
                 */

                padding-left: 22px;
                padding-top: 16px;
                padding-bottom: 14px;

            }

            /**
             * p.
             */

            p {

                /**
                 * Padding.
                 */

                padding: 22px;

                /**
                 * Font related.
                 */

                line-height: 21px;

            }

        }

        /**
         * .option-one.
         */

        .option-one {

            /**
             * Background.
             */

            background-color: #ecedf0;

            /**
             * h2.
             */

            h2 {

                /**
                 * Background.
                 */

                background-color: #434a68;

            }
           
        }

        /**
         * .option-two.
         */

        .option-two {

            /**
             * Background.
             */

            background-color: #f7f9e9;

            /**
             * h2.
             */

            h2 {

                /**
                 * Background.
                 */

                background-color: #b2c125;

            }
            
        }

        /**
         * .option-three.
         */

        .option-three {

            /**
             * Background.
             */

            background-color: #eeeeee;

            /**
             * h2.
             */

            h2 {

                /**
                 * Background.
                 */

                background-color: #555555;

            }

        }

    }

}

/**
 * .esg-form.
 */

.esg-form {

    /**
     * iframe.
     */

    iframe {

        /**
         * Sizing.
         */

        min-height: 830px;

    }

}
