// Remember: do NOT edit this file.

:root {
    font-size: 16px;
}

.latest-blog-post-container p.blog-post-title {
    font-size: 1rem; // 16px
}

.testimonials-area p {
    font-size: 1.125rem; // 18px
}

.testimonials-area h2 {
    font-size: 1.5625rem; // 25px
}

a {
    font-size: 0.9375rem; // 15px
}

h2 {
    font-size: 1.25rem; // 20px
}

p {
    font-size: 0.9375rem; // 15px
}

h1 {
    font-size: 2rem; // 32px
}

.homepage-hero h2 {
    font-size: 5.8125rem; // 93px
}
