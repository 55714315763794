.team-members-container {

    @include breakpoint($up-to-desktop) {
        
    }

    .full-profile-content {
        display: none;
    }

    .inner-content-container {
        p {
            padding-top: 0.8rem !important;
        }
    }

    h2 {
        font-weight: bold;
        margin-bottom: 1rem;
    }

}

.team-member-profile-container-wrapper {

    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 400;
    border: 1.25rem solid white;
    display: none;

    .team-member-profile-container {

        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #181b22;
        opacity: 0.95;
        overflow: hidden;
        overflow: scroll;
        padding: 4.25rem 4.25rem 3rem 4.25rem;

        @include breakpoint(max-width 600px) {
            padding: 2rem;
        }

        p {
            color: $brand-five;
            width: 75%;

            @include breakpoint(max-width 768px) {
                width: 85%;
            }

            @include breakpoint(max-width 600px) {
                width: 100%;
            }
        }

        p.name {
            color: $brand-one;
            font-size: 2rem;
            line-height: 2rem;
            padding-bottom: 0.7rem;
            text-transform: uppercase;
            font-weight: bold;
        }

        p.job-title {
            font-size: 1.25rem;
            text-transform: uppercase;
            padding-bottom: 0;
            font-weight: bold;
        }

        .close-profile {
            margin-bottom: 4rem;
            display: block;
            width: 2rem;
            height: 2rem;
            position: relative;
            text-transform: uppercase;
            color: $brand-five;
            font-size: 0.8rem;
            letter-spacing: 0.15rem;

            strong {

            }

            &:hover {

                color: $brand-one;

                &::before {
                    color: $brand-one;
                }

            }

            // Removed because of not appearing in some
            // IE11 browsers.
            // &::before {
            //     @include fontello;
            //     display: block;
            //     content: '\E800';
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 2rem;
            //     height: 2rem;
            //     font-size: 2rem;
            //     color: $brand-five;
            // }

        }

        .social-links {

            a {
                &:hover {
                    &::before,
                    &::after {
                        color: $brand-five !important;
                    }
                }
            }

            .mail {

                a {

                    &::before {
                        @include fontello;
                        content: '\E802';
                        width: 2rem;
                        height: 2rem;
                        font-size: 0.9rem;
                        top: 0.6rem;
                        left: 0;
                        position: absolute;
                        padding-right: 0.5rem;
                        color: $brand-one;
                    }

                }



            }

        }

        .profile-header {

            position: relative;
            margin-bottom: 1rem;
            width: 75%;

            @include breakpoint(max-width 768px) {
                width: 85%;
            }

            @include breakpoint(max-width 600px) {
                width: 100%;
            }

            .contact-container {
                position: absolute;
                right: 0;
                bottom: 0;
            }

        }

    }

}
