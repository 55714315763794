/**
 * .landing-page.
 */

.landing-page {

    /**
     * h1.
     */

    h1 {

    }

    /**
     * .landing-page-content-footer.
     */

    .landing-page-content-footer {

        /**
         * Padding.
         */

        padding-top: 1rem;

        /**
         * a.
         */

        a {

            /**
             * Margin.
             */

            margin-right: 0.8rem;

        }

    }

}

/**
 * .landing-page.
 */

.landing-page {

    /**
     * .image-container.
     */

    .image-container{

        /**
         * Margin.
         */

        margin-bottom: 2rem;

        /**
         * img.
         */

        img {

            max-width: 100%;

        }

    }

}

/**
 * .landing-page .sidebox.
 */

.landing-page .sidebox {

    /**
     * Background.
     */

    background-color: $brand-one;

    /**
     * Padding.
     */

    padding: 2rem;

    /**
     * Font related.
     */

    color: $brand-two;

    /**
     * Margin.
     */

    margin-bottom: 1rem;

    /**
     * @include.
     */

    @include breakpoint(max-width 950px) {

    }

    /**
     * p.
     */

    p {
        color: $brand-two;
    }

    /**
     * h3.
     */

    h3 {
        text-transform: uppercase;
        padding-bottom: 1rem;
        font-weight: bold;
    }

    /**
     * ul.
     */

    ul {

        /**
         * Padding.
         */

        padding-left: 1.2rem;

        /**
         * li.
         */

        li {

            /**
             * Font related.
             */

            color: $brand-two;
            line-height: 1.3rem;

            /**
             * List.
             */

            list-style: disc;

            /**
             * Padding.
             */

            padding-bottom: 0.8rem;
            
            /**
             * &:last-child.
             */

            &:last-child {

                /**
                 * Padding.
                 */

                padding-bottom: 0;

            }

        }

    }

}
