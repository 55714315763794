/**
 * .single-iframepages.
 */

.single-iframepages {

    /**
     * iframe.
     */

    iframe {

        /**
         * Sizing.
         */

        width: 100%;

        /**
         * Border.
         */

        border: 2px solid gray;

    }

}