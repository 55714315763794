.latest-blog-post-container {
    margin-top: 4rem;

    .blog-post-title {
        font-weight: bold;
        color: $brand-two;
    }

    h2 {
        margin-top: 0;
        text-transform: none;
    }

    .content-row {
        
    }

    .left-column,
    .right-column {
        width: 50%;
        float: left;

        @include breakpoint($up-to-mobile) {
            width: 100%;
            float: none;
        }
    }

    .left-column {

    }

    .right-column {
    padding-bottom: 0.1rem;
    }
}
