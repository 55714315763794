.contact-form-block {

    h3 {
        color: $brand-two;
        margin-bottom: 1.2rem;
        text-transform: uppercase;
    }

    @include breakpoint($up-to-desktop) {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }

}

.contact-form-container {

    .content-row {
        padding-top: 0;

    }

    .success-container {
        display: none;
    }

    .error-container {
        display: none;
    }

    form {

        div.frm_error_style {
            padding-bottom: 1rem;
            line-height: 1.5rem;
            font-weight: bold;
            color: rgb(184, 77, 68);
        }

        div.frm_error {
            margin-bottom: 1rem !important;
            line-height: 1.4rem;
        }

        .column {


            div {
                position: relative;
                overflow: hidden;
                width: 100%;


                &.frm_error {
                    color: rgb(184, 77, 68);
                }

                &.message-container {
                    overflow: hidden;
                }

                &.select-list-container {
                    label {
                        display: none;
                    }

                    select {
                        line-height: 1.2rem;
                    }
                }

                label {
                    position: absolute;
                    left: 1rem;
                    top: 1.3rem;
                    pointer-events: none;
                    color: $brand-three;
                    font-size: 0.8125rem;
                    font-weight: normal;
                }

                &.has-focus,
                &.has-text {
                    input,
                    textarea {

                    }

                    label {
                        display: none;
                    }
                }
            }

            .terms-container {

                .frm_checkbox {
                    padding-bottom: 1rem;
                }

                label {
                    position: relative;
                    left: auto;
                    top: auto;
                    pointer-events: all;
                    line-height: 1.3rem;

                    a {
                        font-size: 0.8125rem;
                    }
                }

                input {
                    
                    width: auto;
                    height: auto;
                    margin-bottom: 0;
                }

            }

            input,
            textarea,
            select {
                width: 100%;
                border: 1px solid $brand-one;
                margin: 0 0 1rem 0;
                height: 3.2rem;
                padding: 1rem 0 1rem 1rem;
                font-size: 0.8125rem;
                color: $brand-three;
                background-color: transparent;
                outline: 0;

                &#firstname,
                &#lastname,
                &#phonenumber,
                &#jobtitle {

                }

                &#companyname,
                &#field_mn6x3 {

                }

                &#message,
                &#field_message { // the Formidable form comment field ID
                    height: 11rem;
                    padding-top: 1rem;
                    line-height: 1.2rem;
                }

            }

            select {
                border-radius: 0px;
                -webkit-appearance: none;
            }

            @include breakpoint($xs) {
                width: 100%;
                float: none;
            }

        }

        button {
            color: $brand-one;
            padding: 1rem 5rem 1rem 1rem;
            border: 1px solid $brand-one;
            display: inline-block;
            margin-top: 0.2rem;
            position: relative;
            overflow: hidden;
            transition: color 80ms linear;
            font-size: 0.8125rem;
            background-color: transparent;

            &:hover {
                text-decoration: none;
                color: $brand-five;

                &::before {
                    top: 0;
                }
            }

            &.foreground-light {
                color: $brand-five;
            }

            &::before {
                content: '';
                display: block;
                position: absolute;
                background-color: $brand-one;
                width: 100%;
                height: 100%;
                top: 100%;
                left: 0;
                z-index: -1;
                transition: top 80ms linear;
            }

        }

        div.frm_submit {
            clear: both;
        }

    }

}

.two-col.get-in-touch {
    padding-bottom: 4vw;

    ul {
        &.social-links {
            padding-left: 0;
        }
    }
}

.get-in-touch.full-width-image-container {
    position: relative;
    overflow: visible;

    &.get-in-touch {

    }

    .slant {

        position: absolute;
        width: 100%;
        height: 25vw;
        top: -1vw;
        left: 0;
        overflow: hidden;


        &::before {
            content: '';
            display: block;
            position: absolute;
            background-repeat: no-repeat;
            background-size: cover;
            width: 110%;
            height: 25vw;
            left: -0.5rem;
            z-index: 100;
            background-image: url(../svg/get-in-touch-image-shape.svg)
        }

    }

}

.decorative-divider-with-image {

    position: relative;

    padding-top: 12.5%;

    canvas {
        position: absolute;
        top: -0.5rem;
    }

    img {
        width: 100%;
    }

}
