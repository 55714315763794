.homepage-hero {
    background-color: $brand-one;
    color: $brand-two;
    // padding-top: 6.25rem;
    // padding-bottom: 8.75rem;
    
    padding-top: 8rem;
    padding-bottom: 8rem;

    position: relative;
    // min-height: 27.75rem;
    z-index: 1;
    overflow: hidden;

    @include breakpoint($up-to-mobile) {
        // padding-top: 2rem;
        // padding-bottom: 4rem;
        // min-height: 20rem;

        padding-top: 5rem;
        padding-bottom: 5rem;
    }

    h2 {
        padding: 0;
        margin: 0;
        font-weight: bold;
        text-transform: none;
        line-height: 6.6rem;

        @include breakpoint($up-to-mobile) {
            font-size: 3.0rem;
            line-height: 3.5rem;
        }
    }

    &.news-and-views,
    &.get-in-touch {
        z-index: 1;

        &::after {
            background-image: url(../svg/hero-news-and-views-slant.svg);
            z-index: -1;
        }
    }

    &.resources {
        z-index: 1;

        &::after {
            background-image: url(../svg/hero-resources-slant.svg);
            z-index: -1;
            bottom: -0.1rem;
            right: -4rem;
        }
    }

    &::after {

        content: '';
        display: block;
        position: absolute;
        background-repeat: no-repeat;
        background-image: url(../svg/hero-homepage-slant.svg);
        height: 32rem;
        width: 32rem;
        right: -0.1rem;
        bottom: -0.5rem;
        z-index: -1;

        @include breakpoint($up-to-mobile) {
            display: none;
        }

    }

    .dots-container {
        ul {
            margin-left: 0.5rem;
            margin-top: 2rem;

            li {
                width: 0.7rem;
                height: 0.7rem;
                border-radius: 0.5rem;
                margin-right: 0.5rem;
                border-radius: 1rem;
                display: inline-block;
                padding: 0 !important;
                list-style: none;

                cursor: pointer;

                button {
                    display: none;
                }

                background-color: $brand-five;

                &.slick-active {
                    background-color: $brand-five;
                }
            }
        }
    }

}
