article.post.not-found {

}

h1.entry-title {
    padding-top: 3rem;
    letter-spacing: 0rem;
    font-size: 2rem;
    margin-left: 0;
    padding-bottom: 2rem;
}

h2.entry-title {
    text-transform: uppercase;
    padding-bottom: 0.5rem;
    font-size: 1.6rem;
    letter-spacing: 0;
    margin-top: 0;

    a {
        font-size: 1.2rem;
        color: $brand-two;

        &:hover {
            color: $brand-one;
        }
    }
}

article.hentry
{
    background-color: $brand-six;
    padding: 2rem 1rem 1rem 1rem;
    margin-bottom: 2rem;

    .a-btn {
        margin-bottom: 1rem;
    }
}

.error404 {

    h1 {

    }

    input {
        border: 1px solid #ccc;
        margin: 0 0 1rem 1rem;
        height: 3.2rem;
        padding: 1rem 0 1rem 1rem;
        font-size: 0.8125rem;
        color: $brand-three;
        background-color: transparent;
        outline: 0;

        @include breakpoint($up-to-mobile) {
            display: block;
            width: 100%;
            margin-top: 1rem;
            margin-left: 0;
        }
    }

    input[type=text] {
        width: 15rem;
    }

    input[type=submit] {
        color: $brand-one;
        padding: 1rem 5rem 1rem 1rem;
        border: 1px solid $brand-one;
        display: inline-block;
        margin-top: 0.2rem;
        position: relative;
        overflow: hidden;
        font-size: 0.8125rem;
        background-color: transparent;
        z-index: 1;

        @include breakpoint($up-to-mobile) {
            text-align: center;
            padding-left: 0;
            padding-right: 0;
        }

        &:hover {
            text-decoration: none;
            color: $brand-five;
            background-color: $brand-one;

            &::before {
                top: 0;
            }
        }

        &.foreground-light {
            color: $brand-five;
        }

    }

}
