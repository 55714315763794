ul.social-links {
    overflow: auto;
    margin-bottom: 0rem;
    display: inline-block;

    li {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        float: left;
        overflow: hidden;
        margin-right: 0.1rem;
        position: relative;
        text-align: center;

        a::after {
            display: block;
            width: 1.5rem;
            height: 2rem;
            position: absolute;
            top: 0;
            left: 0;
            color: $brand-one;
            font-size: 1.3rem;
        }

        a:hover {
            &::after {
                color: $brand-two;
            }
        }

        &.linkedin a::after {
            content: '\f0e1';
            padding-top: 0.3rem;
        }
        &.facebook a::after {
            content: '\f09a';
            padding-top: 0.4rem;
        }
        &.twitter a::after {
            content: '\f099';
            padding-top: 0.4rem;
        }

        span {
            display: block;
            position: absolute;
            left: -9999px;
        }
    }

}

.jssocials {

    padding-left: 0;
    margin-left: -0.3rem;

    .jssocials-share {
        position: relative;
        margin: 0;
        width: 2.0rem;
        height: 2.4rem;

        &::before {
            content: '';
            font-size: 2.4rem;
            width: 2.4rem;
            height: 2.4rem;
        }

        a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            padding-top: 0.6rem;
            color: $brand-one;

            &:hover {
                color: $brand-two;
            }
        }

        i {
            font-size: 1.3rem;
            &::before {

            }
        }
    }

}
